$lighter: #373940;
/* light blue */
$dark: #282c34;
/* dark blue */
$darker: #20232a;
/* really dark blue */
$brand: #61dafb;
/* electric blue */
$brand-light: #bbeffd;
$text: #1a1a1a;
/* very dark grey / black substitute */
$subtle: #6d6d6d;
/* light grey for text */
$subtle-on-dark: #999;
$divider: #ececec;
/* very light grey */
$note: #ffe564;
/* yellow */
$error: #ff6464;
/* red */
$gray: #fafafb;
$white: #ffffff;
$border: #ccc;
$black: #000000;
$success: #198754;
$primary: #0d6efd;
$oneGroup: #16a3dc;
$disabled: #cb1818;

$min-bar-height: 4.8rem;

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.img-logo-pok {
  content: url("../assets/groupone_logo.png");
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.tooltip,
.popover {
  position: fixed;
}

html {
  font-size: 14px;
}

.main-card {
  border: 0 !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.modal-90w {
  max-width: none !important;
  width: 90%;
}

.max-height-modal .modal-body {
  max-height: 800px !important;
  overflow-y: auto;
}

.modal-content {
  -webkit-box-shadow: -3px 10px 16px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: -3px 10px 16px -13px rgba(66, 68, 90, 1);
  box-shadow: -3px 10px 16px -13px rgba(66, 68, 90, 1);
  border: none;
  padding: 0.7rem;
}

.modal-footer,
.modal-header {
  border: none;
}

.mainbar {
  min-height: $min-bar-height;
  background-color: $darker;
  z-index: 1;
  box-shadow: 0px 1px 10px #999;

  @include media-breakpoint-up(md) {
    position: sticky;
    top: 0rem;
    z-index: 1040;
  }
}

.mainbarlink {
  margin-left: $spacer;
}

.mainbarlink:global(.active) {
  font-weight: 700;
}

.gray> :not(:first-child) {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.nav {
  top: 4rem;
  background-color: $gray;
}

.nav-menu {
  top: 4rem;
  background-color: rgba(250, 250, 251, 0.8);
}

.main {
  order: 1;
  padding: 2rem 2rem;
  background-color: $gray;

  @include media-breakpoint-down(sm) {
    padding: 1rem 0.83rem;
  }

  &>h2:not(:first-child) {
    margin-top: 3rem;
  }

  >h3 {
    margin-top: 1.5rem;
  }

  >ul li,
  >ol li {
    margin-bottom: 0.25rem;
  }

  >table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
      display: block;
      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }

  @include media-breakpoint-up(lg) {

    >ul,
    >ol,
    >p {
      max-width: 80%;
    }
  }
}

.sidepanel {
  $top: $min-bar-height;

  order: 2;
  position: sticky;
  top: $top;
  height: calc(100vh - #{$top});
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 0.875rem;
  overflow-y: auto;

  &>ul {
    padding-left: 0;
    border-left: 1px solid $divider;

    & ul {
      padding-left: 1rem;
    }
  }
}

.sidepanel-listitem {
  & a {
    display: block;
    padding: 0.125rem 1.5rem;
    color: transparentize($text, 0.4);

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.menubutton {
  line-height: 1;
  color: #212529;
}

.menu-container-demo::before {
  background-image: url("../assets/demo.jpg");
  background-position: left;
  background-repeat: repeat;
  background-size: 100%;
}

.menu-container {
  position: relative;
  overflow: hidden;
}

.menu-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  z-index: -1;
}

.menu-container .d-block {
  opacity: 1;
}

.sidepanel-forsidenav {
  $top: $min-bar-height;

  background-color: $white;

  @include media-breakpoint-up(md) {
    position: sticky;
    top: $top;
    z-index: 1000;
    height: calc(100vh - #{$top} - 0.5rem);
    background-color: $white;
    border-right: 1px solid $divider;
  }
}

.list-group-item.active {
  background-color: $oneGroup;
  border-color: $oneGroup;
}

.announcement {
  padding: 20px;
}


.overflow-wrapper {
  @include media-breakpoint-up(md) {
    display: block !important;
    height: 100% !important;
  }
}

.table-of-contents {
  @include media-breakpoint-up(md) {
    height: 100% !important;
    overflow: auto;
    margin-right: -15px;
    padding-right: 15px;
  }
}

.table-of-contents-link {
  color: $text !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition: background-color 0.2s ease-out;
  border-radius: 5px;
}

.table-of-contents-link {
  &.active {
    font-weight: 500;
    color: $text !important;
    background: #16a4dc29;
  }

  &.active-mainitem {
    color: $text !important;
    background: #16a4dc1e;
  }

  &:hover {
    background: #16a4dc4b;
  }
}

.table-of-contents-sublink {
  font-size: 90%;
  padding-top: 0.5rem;
  padding-left: 1.5rem;
  margin-left: 1rem;
}

.toc-link-icon {
  margin-left: 0em;
  margin-bottom: 0.2em;
}

.toc-link-heading {
  margin-left: 1em;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: white;
}

.ou-root-node {
  font-family: sans-serif;
}

.ou-leaf-node {
  font-family: sans-serif;
}

.table thead {
  background-color: $darker;
  color: $white;
}

.overflow-x-auto {
  overflow-x: auto;
}

.nav-link.active {
  font-weight: normal;
}

.nav-logout:hover {
  color: rgba(255, 255, 255, 0.75);
}

.nav-logout {
  color: rgba(255, 255, 255, 0.55);
  padding-left: 0;
}

.nav-logout-icon {
  display: block;
  margin-top: 0.35rem;
}

.accordion-button {
  background-color: $divider;
}

.grid-page-size {
  width: 5rem;
}

.page-item {
  z-index: 0;
}

.grid-filter-size {
  width: 20rem;
}

.grid-clickable-row {
  cursor: pointer;
}

.grid-not-clickable-row {
  cursor: default;
}

.grid-filter-column {
  width: 100%;
  box-sizing: border-box;
}

.textarea-1 {
  max-height: 2.2rem;
  height: 2.2rem;
}

.textarea-2 {
  max-height: calc(2 * (1.7rem) + 0.5rem);
  height: calc(2 * (1.7rem) + 0.5rem);
}

.textarea-3 {
  max-height: calc(3 * (1.7rem) + 0.5rem);
  height: calc(3 * (1.7rem) + 0.5rem);
}

.textarea-4 {
  max-height: calc(4 * (1.7rem) + 0.5rem);
  height: calc(4 * (1.7rem) + 0.5rem);
}

.textarea-5 {
  max-height: calc(5 * (1.7rem) + 0.5rem);
  height: calc(5 * (1.7rem) + 0.5rem);
}

.textarea-6 {
  max-height: calc(6 * (1.7rem) + 0.5rem);
  height: calc(6 * (1.7rem) + 0.5rem);
}

.toast-message {
  min-height: 3rem;
}

.loading-spinner-height {
  min-height: 3rem;
}

.col-form-label {
  font-weight: 500;
}

.col-form-static {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
}

.dropdown-item:active {
  background-color: $success;
}

.dropdown-menu>.dropdown-item.active {
  background-color: $white;
}

.dropdown-menu>.dropdown-item:hover {
  background-color: $divider;
}

.dropdown-menu>.dropdown-item {
  color: $black;
  text-decoration: none;
}

.modal-header-with-alert {
  display: block;
  padding-bottom: 0;
}

.modal-on-modal {
  z-index: 1065;
}

.modal-on-modal-backdrop {
  z-index: 1060;
}

.toc * dd {
  margin-left: 1rem;
}

.section * .figure,
.section>.figure,
.chapter * .figure,
.chapter>.figure {
  display: block;
}

th[align="center"],
.figure>.title {
  text-align: center;
}

.dropdown-menu.show {
  width: max-content;
}

.max-width-33vw {
  max-width: 33vw;
}

.overflowx-clip {
  overflow-x: clip;
}

.rte-editor {
  box-sizing: border-box;
  border: 1px solid $border;
  cursor: text;
}

.rte-inner {
  min-height: 50vh;
  display: flex;
  flex-direction: column-reverse;
}

.rte-editor .narrow {
  min-height: 20vh;
}

.rte-editor .narrow30 {
  min-height: 30vh;
}

.rte-textbox {
  padding: 1rem;
  flex: 1;
}

.rte-toolbar {
  border-bottom: 1px solid $border;
  z-index: 0;
  padding: 0.5rem;
  padding-left: 1em;
  box-sizing: border-box;
}

.rte-toolbar-button {
  background: none;
  border: none;
  float: left;
  width: 2.5rem;
  height: 2.5rem;
}

.rte-toolbar-button:not([disabled]) {
  cursor: pointer;
}

.rte-toolbar-button:not([disabled]):hover,
.rte-toolbar-button:not([disabled]).rte-toolbar-button-active {
  background-color: $gray;
}

.rte-headers {
  margin-top: 0.6rem;
  margin-right: 0.4rem;
  border: none;
  float: left;
}

.rte-headers:not([disabled]) {
  cursor: pointer;
}

.rte-header-1 {
  font-size: 2.5rem;
  font-weight: 500;
}

.rte-header-2 {
  font-size: 2rem;
  font-weight: 500;
}

.rte-header-3 {
  font-size: 1.75rem;
  font-weight: 500;
}

.rte-add-image {
  margin-bottom: 0.1rem;
}

.rte-toolbar-separator {
  float: left;
  border-right: 1px solid $divider;
  height: 1.7rem;
  margin: 0.4rem 0.4em;
}

.rte-inline-editor {
  cursor: text;
  display: flex;
  flex-direction: row;
}

.rte-inline-toolbar {
  display: flex;
  flex-direction: row;
}

.rte-inline-toolbar-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0;
  margin-right: 0;
}

.rte-inline-editor .form-control:focus-within {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.rte-inline-editor .form-control {
  padding-bottom: 0.26rem;
}

.DraftEditor-editorContainer {
  overflow: auto;
  max-height: 600px;
  z-index: 0 !important;
}

.rte-inline-editor .DraftEditor-editorContainer {
  z-index: 0;
}

div.vertical.js-focus-visible {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
}

textarea.form-control {
  overflow: hidden;
}

.form-sign-in {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;

  img {
    max-height: 57px;
  }

  z-index: 3;
}

.memos-threads-body {
  max-height: 60vh;
  min-height: 60vh;
}

.memos-pulsing-circle {
  top: 1px !important;
}

.threads-filter-spinner {
  width: 1rem;
}

.col-xxl-20p {
  @media (min-width: 1400px) {
    flex: 0 0 auto;
    width: 20%;
  }
}

.max-rows-5 {
  resize: vertical;
  max-height: 8rem;
}

.mh-md-33 {
  @media (max-width: 992px) {
    max-height: 33% !important;
  }
}

.mh-md-66 {
  @media (max-width: 992px) {
    max-height: 66% !important;
  }
}

.border-left-light {
  border-left: 1px solid #D2D2D2;
}

.mh-lg-100 {
  @media (min-width: 992px) {
    max-height: 100% !important;
  }
}

.navbar-brand {
  position: relative;
  background-color: transparent;
  padding: 0;
  color: black;
  z-index: -1;
  height: 100%;
}

.navbar-brand::before {
  content: "";
  position: absolute;
  top: -10px;
  right: -30px;
  bottom: -10px;
  left: -9999px;
  background-color: #ffffff;
  transform: skew(30deg);
  z-index: -1;
  margin-top: 0;
}

.bg-image {
  background-image: url("../assets/login_background.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.icon-red {
  fill: darkred;
}

.icon-green {
  fill: $success;
}

.icon-blue {
  fill: $primary;
}

.text-green {
  color: green !important;
}

.text-red {
  color: red !important;
}

.bg-custom-green {
  background-color: #98ec8c;
}

.bg-custom-orange {
  background-color: #fbe3b3;
}

.bg-custom-yellow {
  background-color: #f5e1a7;
}

.bg-custom-dark-yellow {
  background-color: #f5a756;
}

.bg-custom-pink {
  background-color: #f76c6c;
}

.bg-custom-light-red {
  background-color: #ff9999;
}

.bg-custom-red {
  background-color: #f9403f;
}

@media (max-width: 600px) {
  .navbar-brand {
    display: none;
  }
}

.last-editor {
  position: absolute;
  color: $primary;
  left: 2vh;
}

.last-editor-inline {
  color: $primary;
  margin-left: 1vh;
}

.modal-on-modal {
  z-index: 1065;
}

.modal-on-modal-backdrop {
  z-index: 1060;
}

.table .inactive-row {
  text-decoration: line-through;
}

tr.grid-highlight-row td {
  background-color: #f4f473;
}

.readonly-row {
  color: #9e9e9e !important;
}

input[type="date"] {
  max-width: 150px;
}

input[type="month"] {
  max-width: 150px;
}

.side-by-side {
  display: flex;
  justify-content: space-between;
}

.left {
  width: 50%;
}

.right {
  width: 50%;
  text-align: right;
}

.left-70 {
  width: 68%;
}

.right-30 {
  width: 28%;
  text-align: right;
}

.full-width {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.hidden {
  display: none;
}

.file-input-group:hover>span {
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  transition:
    border-color 0.5s ease,
    background-color 0.5s ease;
}

.nav[role="tablist"] {
  --bs-nav-link-color: rgba(0, 0, 0, 0.85);
  --bs-nav-link-hover-color: rgba(0, 0, 0, 0.92);
}

.accordion-header {
  --bs-accordion-active-bg: #f9f49b;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0 rgba(255, 243, 69, 0.25);
}

.active-item-accordion {
  --bs-accordion-border-color: #052c65;
}

.deleted-item-accordion {
  text-decoration: line-through;
}

.new-item-accordion .accordion-button {
  background-color: #ff0a0aa0;
}

.profile-accordion .accordion-button {
  background-color: #cfe2ff;
}

.border-bold {
  border: 1px solid #666666;
}

.layout-accordion-header {
  width: 98%;
  display: flex;
  gap: 16px;
}

.grow1 {
  flex-grow: 1;
}

.marginLeft {
  margin-left: auto;
}

.amounts-align-right {
  text-align: right;
}

.grid-summary-foot {
  font-weight: bold;
  border-top: 2px solid;
}

.color-primary {
  color: $primary;
}

.saving-toast {
  min-height: 70px;
}

.font-90 {
  font-size: 88%;
}

.not-saved-tab {
  background-color: #ff0a0aa0 !important;
}

.title-size {
  font-size: 1.2rem;
}

.clone-count {
  margin-bottom: -50px;
  margin-top: 40px;
}

.z-index-0 {
  z-index: 0;
}

.clone-date-selector-not {
  font-size: 70%;
  border-color: red !important;
  border-width: 2px !important;
}

.clone-date-selector-ok {
  font-size: 70%;
  border-color: $success !important;
  border-width: 2px !important;
}

.table .error-row {
  background-color: #ff55557a;
}

.table .warning-row {
  background-color: #ff911c
}

.table .info-row {
  background-color: #0eb7ff7a
}

.table .success-row {
  background-color: rgba(2, 128, 255, 0.19);
}

.table .ok-row {
  background-color: #98ec8c
}

.table .blue-row {
  background-color: #0eb7ff7a;
}

.table .green-row {
  background-color: #98ec8c
}

.table .orange-row {
  background-color: #ff911c;
}

.table .red-row {
  background-color: #ff55557a;
}


.noWrap {
  white-space: nowrap;
}

.btn-transparent {
  border: none;
  box-shadow: none;
  color: black;
  padding-right: 0px !important;
}

.btn-transparent:hover,
.btn-transparent:focus,
.btn-transparent:active {
  background-color: initial;
  color: black;
  outline: none;
  box-shadow: none;
  --bs-btn-active-bg: #f8f9fa00;
}

.icon-in-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-disabled {
  --bs-alert-bg: #E9ECEF;
  --bs-alert-border-color: none;
  --bs-alert-color: #212529;
}

.ql-editor {
  min-height: 150px;
}

.img-with-border {
  border: 1px solid #212529;
}

.copy-button {
  background-color: #f8f9fa;
  margin-top: -10px;
  margin-left: -10px;
  margin-bottom: 5px;
  color: #9c2062;
}

.marker {
  display: inline-block;
  padding: 4px 8px;
  font-size: 90%;
  border-radius: 12px;
  text-align: center;
}

.alert-unsaved {
  --#{$prefix}alert-color: var(--#{$prefix}danger-text-emphasis);
  --#{$prefix}alert-bg: #{$error};
  --#{$prefix}alert-border-color: var(--#{$prefix}danger-border-subtle);
  --#{$prefix}alert-link-color: var(--#{$prefix}danger-text-emphasis);
}