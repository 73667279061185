.snowfall {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    pointer-events: none; 
    z-index: 2;
  }
  
  .snowflake {
    position: absolute;
    top: -10px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.8;
    animation: fall linear infinite;
  }
  
  @keyframes fall {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100vh);
    }
  }