.pdf-viewer {
  border: #484343;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  border-radius: 5px;
}

.pdf-viewer__controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.react-pdf__Page__textContent  {
  color: transparent;
  opacity: 0.5;
}

.react-pdf__Page__textContent ::selection {
  background: #7a7af1;
}